var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bind.stream)?_c('div',[(_vm.formData.intId)?_c('ed-chat',{attrs:{"boolExibirChat":_vm.bind.boolExibirChat,"formData":_vm.formData},on:{"mensagem":function (x) {
        _vm.bind.intTotalMensagem = x;
      }}}):_vm._e(),_c('div',{staticClass:"row justify-content-center text-center pt-0 mt-0"},[_c('div',{staticClass:"col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 text-center"},[_c('div',{staticClass:"row justify-content-center pa-3 mt-0 text-center"},[_vm._l((_vm.bind.players),function(player,index){return _c('div',{key:index,staticClass:"\n            col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-4\n            ma-0\n            pa-0\n            text-center\n          "},[_c('div',{staticClass:"ma-1 video-stream"},[_c('div',{staticClass:"video-stream-top"},[_c('ed-button',{attrs:{"icon":"","text":"","iconClass":_vm.$utilidade.getIcone(
                    'microfone-' +
                      (player.stream.getAudioTracks()[0].enabled
                        ? 'on'
                        : 'of')
                  ) + ' fa-2x',"color":"white"}}),_c('ed-button',{attrs:{"icon":"","text":"","iconClass":_vm.$utilidade.getIcone(
                    'video-' +
                      (player.stream.getVideoTracks()[0].enabled
                        ? 'on'
                        : 'of')
                  ) + ' fa-2x',"color":"white"}})],1),_c('video',{staticClass:"video-player",attrs:{"autoplay":""},domProps:{"srcObject":player.stream}}),_c('div',{staticClass:"video-stream-bottom"},[_vm._v(_vm._s(player.user.name))])])])}),_c('div',{class:(_vm.bind.players.length
              ? 'col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-4'
              : 'col-9') +
            (_vm.bind.players.length > 0 && _vm.$root.$session.isMobile
              ? ' video-player-pip '
              : '') +
            ' text-center ma-0 pa-0'},[_c('div',{class:'ma-1 video-stream'},[_c('div',{staticClass:"video-stream-top"},[_c('ed-button',{attrs:{"icon":"","text":"","iconClass":_vm.$utilidade.getIcone(
                    'microfone-' +
                      (_vm.formConfig.boolMigrofoneLigado ? 'on' : 'of')
                  ) + ' fa-2x',"color":"white"}}),_c('ed-button',{attrs:{"icon":"","text":"","iconClass":_vm.$utilidade.getIcone(
                    'video-' +
                      (_vm.formConfig.boolVideoLigado ? 'on' : 'of')
                  ) + ' fa-2x',"color":"white"}})],1),_c('video',{class:'video-player',attrs:{"id":"teste","autoplay":"","muted":""},domProps:{"muted":true,"srcObject":_vm.bind.stream}}),_c('div',{staticClass:"video-stream-bottom"},[_vm._v("Você")])])]),(!_vm.formData.boolConectado)?_c('div',{staticClass:"col"},[(_vm.formData.intUsuarioLogadoId)?[_c('h4',[_vm._v("Pronto para participar?")]),_c('ed-button',{attrs:{"color":"primary","label":"Participar agora"},on:{"click":_vm.entrar}})]:_vm._e(),(!_vm.formData.intUsuarioLogadoId)?[_c('h4',[_vm._v("Qual é seu nome?")]),_c('ed-input-text',{attrs:{"name":"name","label":"Seu nome","rules":"required"},model:{value:(_vm.formData.strNome),callback:function ($$v) {_vm.$set(_vm.formData, "strNome", $$v)},expression:"formData.strNome"}}),_c('ed-button',{attrs:{"color":"primary","label":"Pedir para participar","disabled":_vm.formData.strNome ? false : true},on:{"click":_vm.pedirConfirmacao}})]:_vm._e()],2):_vm._e()],2)])]),_c('div',{staticClass:"row mt-0 mt-5",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticStyle:{"width":"100%","position":"fixed","margin-top":"50px","bottom":"0px","padding":"5px","left":"50%","margin-left":"-50%","background":"white","box-shadow":"0px 8px 20px #cccccc, 0px -8px 20px #cccccc"}},[_c('ed-button',{staticClass:"ma-3",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone(
              'microfone-' + (_vm.formConfig.boolMigrofoneLigado ? 'on' : 'of')
            ) + ' fa-3x',"color":"primary","title":_vm.formConfig.boolVideoLigado
              ? 'Desativar microfone'
              : 'Ativar microfone'},on:{"click":_vm.verificarMicrofone}}),_c('ed-button',{staticClass:"ma-3",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone(
              'video-' + (_vm.formConfig.boolVideoLigado ? 'on' : 'of')
            ) + ' fa-3x',"color":"primary","title":_vm.formConfig.boolVideoLigado ? 'Desativar câmera' : 'Ativar câmera'},on:{"click":_vm.verificarVideo}}),(
            _vm.formData.intId &&
            _vm.formData.intUsuarioLogadoId &&
            _vm.formData.boolConectado &&
            _vm.bind.players.length &&
            !_vm.bind.recorder
          )?_c('ed-button',{staticClass:"ma-3",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone('play') + ' fa-3x',"title":"Iniciar Gravação da Chamada","color":"warning"},on:{"click":_vm.iniciarGravacao}}):_vm._e(),(
            _vm.formData.intId &&
            _vm.formData.intUsuarioLogadoId &&
            _vm.formData.boolConectado &&
            _vm.bind.players.length &&
            _vm.bind.recorder
          )?_c('ed-button',{staticClass:"ma-3",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone('stop') + ' fa-3x faa-flash animated',"title":"Parar Gravação da Chamada","color":"warning"},on:{"click":_vm.pararGravacao}}):_vm._e(),(_vm.formData.intId && _vm.formData.boolConectado)?_c('ed-button',{staticClass:"ma-3",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone('chat') + ' fa-3x',"badge":_vm.bind.intTotalMensagem,"color":"primary","title":"Chat"},on:{"click":function($event){_vm.bind.boolExibirChat = !_vm.bind.boolExibirChat}}}):_vm._e(),(_vm.formData.intId && _vm.formData.boolConectado)?_c('ed-button',{staticClass:"ma-3 ml-10",attrs:{"icon":"","iconClass":_vm.$utilidade.getIcone('fone') + ' fa-3x',"title":"Sair da chamada","color":"error"},on:{"click":_vm.leave}}):_vm._e()],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }