<template>
  <div v-if="bind.stream">

    <ed-chat
      @mensagem="
        (x) => {
          bind.intTotalMensagem = x;
        }
      "
      :boolExibirChat="bind.boolExibirChat"
      :formData="formData"
      v-if="formData.intId"
    />

    <div class="row justify-content-center text-center pt-0 mt-0">
      <div
        class="col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 text-center"
      >
        <div class="row justify-content-center pa-3 mt-0 text-center">
          <div
            class="
              col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-4
              ma-0
              pa-0
              text-center
            "
            v-for="(player, index) in bind.players"
            :key="index"
          >
            <div class="ma-1 video-stream">
              <div class="video-stream-top">
                <ed-button
                  icon
                  text
                  :iconClass="
                    $utilidade.getIcone(
                      'microfone-' +
                        (player.stream.getAudioTracks()[0].enabled
                          ? 'on'
                          : 'of')
                    ) + ' fa-2x'
                  "
                  color="white"
                />

                <ed-button
                  icon
                  text
                  :iconClass="
                    $utilidade.getIcone(
                      'video-' +
                        (player.stream.getVideoTracks()[0].enabled
                          ? 'on'
                          : 'of')
                    ) + ' fa-2x'
                  "
                  color="white"
                />
              </div>

              <video
                autoplay
                :srcObject.prop="player.stream"
                class="video-player"
              />
              <div class="video-stream-bottom">{{ player.user.name }}</div>
            </div>
          </div>

          <div
            :class="
              (bind.players.length
                ? 'col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-4'
                : 'col-9') +
              (bind.players.length > 0 && $root.$session.isMobile
                ? ' video-player-pip '
                : '') +
              ' text-center ma-0 pa-0'
            "
          >
            <div :class="'ma-1 video-stream'">
              <div class="video-stream-top">
                <ed-button
                  icon
                  text
                  :iconClass="
                    $utilidade.getIcone(
                      'microfone-' +
                        (formConfig.boolMigrofoneLigado ? 'on' : 'of')
                    ) + ' fa-2x'
                  "
                  color="white"
                />

                <ed-button
                  icon
                  text
                  :iconClass="
                    $utilidade.getIcone(
                      'video-' +
                        (formConfig.boolVideoLigado ? 'on' : 'of')
                    ) + ' fa-2x'
                  "
                  color="white"
                />
              </div>
              <video
                :class="'video-player'"
                id="teste"
                autoplay
                muted
                :srcObject.prop="bind.stream"
              />
              <div class="video-stream-bottom">Você</div>
            </div>
          </div>

          <div class="col" v-if="!formData.boolConectado">
            <template v-if="formData.intUsuarioLogadoId">
              <h4>Pronto para participar?</h4>
              <ed-button
                @click="entrar"
                color="primary"
                label="Participar agora"
              />
            </template>

            <template v-if="!formData.intUsuarioLogadoId">
              <h4>Qual é seu nome?</h4>

              <ed-input-text
                v-model="formData.strNome"
                name="name"
                label="Seu nome"
                rules="required"
              />
              <ed-button
                @click="pedirConfirmacao"
                color="primary"
                label="Pedir para participar"
                :disabled="formData.strNome ? false : true"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-0 mt-5" style="height: 100%">
      <div class="col-12 text-center">
        <div
          style="
            width: 100%;
            position: fixed;
            margin-top: 50px;
            bottom: 0px;
            padding: 5px;
            left: 50%;
            margin-left: -50%;
            background: white;
            box-shadow: 0px 8px 20px #cccccc, 0px -8px 20px #cccccc;
          "
        >
          <ed-button
            class="ma-3"
            icon
            @click="verificarMicrofone"
            :iconClass="
              $utilidade.getIcone(
                'microfone-' + (formConfig.boolMigrofoneLigado ? 'on' : 'of')
              ) + ' fa-3x'
            "
            color="primary"
            :title="
              formConfig.boolVideoLigado
                ? 'Desativar microfone'
                : 'Ativar microfone'
            "
          />

          <ed-button
            class="ma-3"
            icon
            @click="verificarVideo"
            :iconClass="
              $utilidade.getIcone(
                'video-' + (formConfig.boolVideoLigado ? 'on' : 'of')
              ) + ' fa-3x'
            "
            color="primary"
            :title="
              formConfig.boolVideoLigado ? 'Desativar câmera' : 'Ativar câmera'
            "
          />

          <!-- <ed-button
            class="ma-3"
            icon
            v-if="formData.id && formData.connected && bind.peer"
            @click="shareScreen"
            :iconClass="$utilidade.getIcone('compartilhar') + ' fa-3x'"
            color="primary"
          /> -->

          <ed-button
            class="ma-3"
            v-if="
              formData.intId &&
              formData.intUsuarioLogadoId &&
              formData.boolConectado &&
              bind.players.length &&
              !bind.recorder
            "
            icon
            :iconClass="$utilidade.getIcone('play') + ' fa-3x'"
            title="Iniciar Gravação da Chamada"
            color="warning"
            @click="iniciarGravacao"
          />

          <ed-button
            class="ma-3"
            v-if="
              formData.intId &&
              formData.intUsuarioLogadoId &&
              formData.boolConectado &&
              bind.players.length &&
              bind.recorder
            "
            icon
            :iconClass="
              $utilidade.getIcone('stop') + ' fa-3x faa-flash animated'
            "
            title="Parar Gravação da Chamada"
            color="warning"
            @click="pararGravacao"
          />

          <ed-button
            class="ma-3"
            icon
            v-if="formData.intId && formData.boolConectado"
            @click="bind.boolExibirChat = !bind.boolExibirChat"
            :iconClass="$utilidade.getIcone('chat') + ' fa-3x'"
            :badge="bind.intTotalMensagem"
            color="primary"
            title="Chat"
          />

          <ed-button
            class="ma-3 ml-10"
            v-if="formData.intId && formData.boolConectado"
            icon
            :iconClass="$utilidade.getIcone('fone') + ' fa-3x'"
            title="Sair da chamada"
            color="error"
            @click="leave"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Usuario from "./classes/usuario";
import EdChat from "./partials/chat";
import { EdButton, EdModal, EdInputText } from "@/components/common/form";

const { RTCPeerConnection } = window;

export default {
  name: "EdVideo",
  mixins: [],
  components: {
    EdButton,
    EdModal,
    EdInputText,
    EdChat,
  },
  props: {
    consultaVideoChamada: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {},
  provide() {
    return {
      consultaVideoChamada: this.consultaVideoChamada,
      formData: this.formData,
      playSound: this.playSound,
      bind: this.bind,
    };
  },
  data() {
    return {
      roomId: "teste",
      users: new Map(),
      bind: {
        socket: null,
        stream: null,
        peer: null,
        recorder: null,
        intInicioGracacao: 0,
        intTotalMensagem: 0,
        boolPedidoChamada: false,
        boolPedidoChamadaRespondido: false,
        boolExibirChat: false,
        jwt: this.$root.$session.getJwt(true),
        players: [],
        recorders: [],
      },
      rtcConfiguration: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
        ],
      },
      formConfig: {
        boolMigrofoneLigado: true,
        boolVideoLigado: true,
      },
      formData: {
        intId: 0,
        intConsultaVideoId: this.consultaVideoChamada.intId,
        strSessaoSistema: this.consultaVideoChamada.strSessao,
        strNome: null,
      },
    };
  },
  beforeDestroy() {
    this.removerEventos();
  },
  created() {},
  mounted() {
    this.criarSessao = _.debounce(this.criarSessao, 200);

    if (this.bind.jwt) {
      this.formData = {
        intId: 0,
        boolConectado: false,
        strSessaoSocket: null,
        strSessaoSistema: this.consultaVideoChamada.strSessao,
        intConsultaVideoId: this.consultaVideoChamada.intId,
        intUsuarioLogadoId: this.bind.jwt ? this.bind.jwt.user.id : null,
        strNome: this.bind.jwt
          ? this.bind.jwt.user.nome
          : this.consultaVideoChamada.consulta.funcionario.strNome,
      };
    }
    this.initialize();
    this.registrarEventos();
  },
  computed: {},
  methods: {
    initialize() {
      let self = this;
      let mediaDevices = null;

      this.$root.$dialog.loading(true);

      if (navigator.mediaDevices) {
        mediaDevices = navigator.mediaDevices;
      } else if (navigator.mediaDevices.getUserMedia) {
        mediaDevices = navigator.mediaDevices.getUserMedia;
      } else {
        self.$root.$dialog.error(
          "Falha ao criar video chamada. Navegador não compatível"
        );
        return;
      }

      mediaDevices
        .getUserMedia({
          // video: {
          //   width: {
          //     ideal: 1280,
          //   },
          //   height: {
          //     ideal: 720,
          //   },
          // },
          video: true,
          audio: true,
        })
        .then(function (stream) {
          self.bind.stream = stream;
          self.bind.boolCarregado = true;

         setTimeout(() => {
          var elem = document.getElementById("teste");
          elem.requestFullscreen()
         }, 2000);
        })
        .catch(function (err) {
          self.$root.$dialog.error("Falha ao criar video chamada. " + err);
        })
        .finally(function () {
          self.$root.$dialog.loading(false);
        });

      //SESSAO
      if (
        this.$cookie.get(
          "videoChamada-" + this.consultaVideoChamada.strIdentificador
        )
      ) {
        let formData = Object.assign(
          JSON.parse(
            this.$utilidade.base64Decode(
              this.$cookie.get(
                "videoChamada-" + this.consultaVideoChamada.strIdentificador
              )
            )
          ),
          {}
        );

        if (formData) {
          this.formData.strNome = formData.strNome;
          this.formData.strSessaoSistema = formData.strSessaoSistema;
        }
      }
    },

    iniciarGravacao() {
      this.bind.recorder = RecordRTC([this.bind.stream], {
        type: "video",
        //mimeType: 'video/mp4',
        //frameRate: 60,
      });
      this.bind.recorder.startRecording();
      this.bind.intInicioGracacao = new Date().getTime();

      this.bind.players.forEach((element) => {
        let stream = element.stream;
        this.bind.recorder.getInternalRecorder().addStreams([stream]);
      });

      this.dispararEvento("iniciarGravacao", {
        boolConfirmacao: true,
      });
    },

    pararGravacao() {
      console.log("this.bind.recorder", this.bind.recorder);

      if (this.bind.recorder) {
        let self = this;
        this.bind.recorder.stopRecording(function () {
          let blob = self.bind.recorder.getBlob();
          let intDuracaoChamada =
            (new Date().getTime() - self.bind.intInicioGracacao) / 1000;

          // self.$utilidade.invokeSaveAsDialog(
          //   blob,
          //   self.consultaVideoChamada.strIdentificador + ".mkv"
          // );

          self.bind.recorders.push(blob);

          self.$utilidade.fileToBase64(blob).then((objFile) => {
            objFile.strNome =
              self.consultaVideoChamada.strIdentificador +
              "-" +
              self.bind.recorders.length +
              ".mkv";

            self.inserirGravacao({
              arquivo: objFile,
              intDuracaoChamada: intDuracaoChamada,
            });
            self.bind.recorder = null;
          });
        });
      }
    },

    playSound(sound) {
      if (sound) {
        var audio = new Audio("/audio/" + sound + ".mp3");
        audio.play();
      }
    },

    shareScreen() {
      const mediaStream = this.bind.stream;

      const screenTrack = mediaStream.getVideoTracks()[0];

      if (screenTrack) {
        this.replaceTrack(screenTrack);
      }
    },

    replaceTrack(newTrack) {
      const sender = this.bind.peer
        .getSenders()
        .find((sender) => sender.track.kind === newTrack.kind);

      if (!sender) {
        return;
      }

      sender.replaceTrack(newTrack);

      for (const sender of this.bind.peer.getSenders()) {
        sender.track.stop();
      }
    },

    removerEventos() {
      this.$root.$socket.unsubscribe(
        this.consultaVideoChamada.strIdentificador
      );
    },

    registrarEventos() {
      let self = this;

      this.$root.$socket.subscribe(
        this.consultaVideoChamada.strIdentificador,
        "iniciarGravacao",
        function (objRetorno) {
          if (self.formData.strSessaoSistema == objRetorno.strSessaoSistema) {
            return;
          }
          self.$root.$message.warning(
            objRetorno.strNome + " iniciou a gravação da chamada"
          );
        }
      );

      this.$root.$socket.subscribe(
        this.consultaVideoChamada.strIdentificador,
        "verificarControles",
        function (objRetorno) {
          var user = self.users.get(objRetorno.strSessaoSocket);

          if (!user) return;

          let index = self.bind.players.findIndex((c) => user.id == c.user.id);

          if (index != -1) {
            self.bind.players[index].stream.getVideoTracks()[0].enabled =
              objRetorno.boolVideoLigado;
            self.bind.players[index].stream.getAudioTracks()[0].enabled =
              objRetorno.boolMigrofoneLigado;
            self.$forceUpdate();
          }
        }
      );

      this.$root.$socket.subscribe(
        this.consultaVideoChamada.strIdentificador,
        "confirmarEntrada",
        function (objRetorno) {
          if (
            !self.formData.intUsuarioLogadoId ||
            self.formData.strSessaoSistema == objRetorno.strSessaoSistema
          ) {
            return;
          }

          self.playSound("ask");

          self.$root.$dialog
            .confirm(
              objRetorno.strNome +
                " quer participar desta chamada. Permitir a entrada?"
            )
            .then((boolConfirmacao) => {
              self.dispararEvento(
                "confirmarEntradaRetorno." + objRetorno.strSessaoSistema,
                {
                  boolConfirmacao: true,
                }
              );
            })
            .catch(function (err) {
              self.dispararEvento(
                "confirmarEntradaRetorno." + objRetorno.strSessaoSistema,
                {
                  boolConfirmacao: false,
                }
              );
            });
        }
      );

      this.$root.$socket.subscribe(
        this.consultaVideoChamada.strIdentificador,
        "confirmarEntradaRetorno." + self.formData.strSessaoSistema,
        function (objRetorno) {
          self.$root.$dialog.loading(false);
          self.bind.boolPedidoChamadaRespondido = true;

          if (objRetorno.boolConfirmacao) {
            self.entrar();
          } else {
            self.$root.$dialog.error(
              "Não foi possível participar desta chamda. Alguém recusou sua solicitação para participar"
            );
          }
        }
      );
    },

    verificarMicrofone() {
      this.formConfig.boolMigrofoneLigado =
        !this.formConfig.boolMigrofoneLigado;

      if (this.bind.stream) {
        this.bind.stream.getAudioTracks()[0].enabled =
          this.formConfig.boolMigrofoneLigado;
      }
      this.dispararEvento("verificarControles", this.formConfig);
    },

    verificarVideo() {
      this.formConfig.boolVideoLigado = !this.formConfig.boolVideoLigado;

      if (this.bind.stream) {
        this.bind.stream.getVideoTracks()[0].enabled =
          this.formConfig.boolVideoLigado;
      }
      this.dispararEvento("verificarControles", this.formConfig);
    },

    pedirConfirmacao() {
      this.$root.$dialog.loading(true, "Aguarde um momento");
      this.bind.boolPedidoChamada = true;
      this.dispararEvento("confirmarEntrada", this.formData);
      let self = this;

      setInterval(() => {
        if (
          self.bind.boolPedidoChamada &&
          !self.bind.boolPedidoChamadaRespondido
        ) {
          self.$root.$dialog.loading(false);
          self.$root.$dialog.error(
            "Solicitação para participar na chamada não foi respondido a tempo"
          );
        }
      }, 30000);
    },

    entrar() {
      if (!this.formData.boolConectado) {
        this.$root.$dialog.loading(true, "Aguarde um momento");
        this.criarSessao();
      }
    },

    dispararEvento(strEvento, arrayData) {
      arrayData = Object.assign(arrayData, this.formData);

      this.$root.$request
        .post("publico/disparar-evento", {
          strCanal: this.consultaVideoChamada.strIdentificador,
          strEvento: strEvento,
          arrayData: arrayData,
        })
        .then((objResponse) => {});
    },

    initServerConnection() {
      const socket = io("https://"+process.env.VUE_APP_URL_RTC, {
        reconnectionDelayMax: 10000,
        secure:process.env.VUE_APP_SECURE_RTC ? true : true,
        auth: {
          token: "",
        },
        query: {
          room: this.consultaVideoChamada.strIdentificador,
          strNomeUsuario: this.formData.strNome,
          strSessaoSistema: this.formData.strSessaoSistema,
          intUsuarioLogadoId: this.formData.intUsuarioLogadoId,
          intConsultaVideoId: this.consultaVideoChamada.intId,
          strUrlDatabase: this.$root.$session.cliente.strUrlDatabase,
        },
      });

      let self = this;

      socket.on("disconnect-user", function (data) {
        var user = self.users.get(data.id);
        if (user) {
          self.users.delete(data.id);
          user.selfDestroy();
          self.playSound("leave");

          self.$root.$message.warning(data.strNomeUsuario + " saiu da chamda");
        }
      });

      socket.on("call", function (data) {
        let user = new Usuario(data.id, data.strNomeUsuario);
        user.pc = self.createPeer(user);
        self.users.set(data.id, user);

        self.createOffer(user, socket);
      });

      socket.on("offer", function (data) {
        var user = self.users.get(data.id);
        if (user) {
          self.answerPeer(user, data.offer, socket);
        } else {
          let user = new Usuario(data.id, data.strNomeUsuario);
          user.pc = self.createPeer(user);
          self.users.set(data.id, user);
          self.answerPeer(user, data.offer, socket);
        }
      });

      socket.on("answer", function (data) {
        var user = self.users.get(data.id);
        if (user) {
          user.pc.setRemoteDescription(data.answer);
          self.playSound("join");
        }
      });

      socket.on("candidate", function (data) {
        var user = self.users.get(data.id);
        if (user) {
          user.pc.addIceCandidate(data.candidate);
        } else {
          let user = new Usuario(data.id, data, strNomeUsuario);
          user.pc = self.createPeer(user);
          user.pc.addIceCandidate(data.candidate);
          self.users.set(data.id, user);
        }
      });

      socket.on("connect", function (e) {
        self.formData.strSessaoSocket = socket.id;
        self.formData.boolConectado = socket.connected;
        self.playSound("join");
      });

      socket.on("connect_error", function (error) {
        console.log("Connection ERROR!", error);
        self.leave();
        self.playSound("error");
      });

      return socket;
    },

    criarSessao(boolDesabilitarEntradaAutomatica = false) {
      this.$root.$request
        .post("publico/video-chamada", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            if (!objResponse.result || !objResponse.result.intId) {
              this.$root.$dialog.error(
                "Não foi possível criarSessao na vídeo chamada"
              );
            } else {
              this.formData = Object.assign(objResponse.result, {});

              this.$cookie.set(
                "videoChamada-" + this.consultaVideoChamada.strIdentificador,
                this.$utilidade.base64Encode(JSON.stringify(this.formData)),
                {
                  expires: "1h",
                }
              );

              this.bind.socket = this.initServerConnection();
            }
          }
        });
    },

    atualizarChamada(data) {
      let formData = Object.assign(data, this.consultaVideoChamada);

      this.$root.$request
        .put("Consulta/ConsultaVideo", formData)
        .then((objResponse) => {
          console.log("atualizarChamada", objResponse);
        });
    },

    inserirGravacao(data) {
      this.$root.$request
        .post(
          "Consulta/ConsultaVideoGravacao",
          Object.assign(data, {
            intConsultaVideoId: this.consultaVideoChamada.intId,
          })
        )
        .then((objResponse) => {
          console.log("ConsultaVideoGravacao", objResponse);
        });
    },

    leave() {
      this.$root.$dialog.loading(true);

      this.bind.socket.close();
      for (var user of this.users.values()) {
        user.selfDestroy();
      }
      this.users.clear();
      this.formData.boolConectado = false;
      this.removerEventos();
      this.playSound("leave");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    },

    addVideoPlayer(stream, user) {
      let self = this;

      stream.oninactive = function (e) {
        let index = self.bind.players.findIndex(
          (c) => c.stream.id == e.currentTarget.id
        );
        if (index != -1) {
          self.bind.players.splice(index, 1);
        }

        if (self.formData.strSessaoSocket == e.currentTarget.id) {
          self.formData.boolConectado = false;
          self.formData.strSessaoSocket = null;
        }
      };

      let index = this.bind.players.findIndex((c) => c.stream.id == stream.id);

      if (index == -1) {
        this.bind.players.push({ stream: stream, user: user });
      }
    },

    //PEER
    createPeer(user) {
      let self = this;

      var pc = new RTCPeerConnection(this.rtcConfiguration);
      pc.onicecandidate = function (event) {
        if (!event.candidate) {
          return;
        }

        self.bind.socket.emit("candidate", {
          id: user.id,
          candidate: event.candidate,
        });
      };

      for (const track of this.bind.stream.getTracks()) {
        pc.addTrack(track, this.bind.stream);
      }

      pc.ontrack = function (event) {
        if (user.player) {
          return;
        }
        user.player = self.addVideoPlayer(event.streams[0], user);
      };

      pc.ondatachannel = function (event) {
        user.dc = event.channel;
        self.setupDataChannel(user.dc);
      };

      pc.onstream = function (event) {
        var video = document.querySelector("video");
        video.srcObject = stream;
        video.play();
      };

      pc.iceconnectionstatechange = function (event) {};

      pc.negotiationneeded = function (event) {};

      pc.signalingstatechange = function (event) {};

      pc.signalingstatechange = function (event) {};

      this.bind.peer = pc;

      return pc;
    },

    createOffer(user, socket) {
      user.dc = user.pc.createDataChannel("chat");
      this.setupDataChannel(user.dc);

      user.pc.createOffer().then(function (offer) {
        user.pc.setLocalDescription(offer).then(function () {
          socket.emit("offer", {
            id: user.id,
            offer: offer,
          });
        });
      });
    },

    answerPeer(user, offer, socket) {
      user.pc.setRemoteDescription(offer).then(function () {
        user.pc.createAnswer().then(function (answer) {
          user.pc.setLocalDescription(answer).then(function () {
            socket.emit("answer", {
              id: user.id,
              answer: answer,
            });
          });
        });
      });
    },

    setupDataChannel(dataChannel) {
      let self = this;
      dataChannel.onopen = this.checkDataChannelState;
      dataChannel.onclose = this.checkDataChannelState;
      // dataChannel.onmessage = function (e) {
      //   self.addMessage(e.data);
      // };
    },

    checkDataChannelState(dataChannel) {},
  },
  watch: {
    formData: {
      handler: function (newVal, oldVal) {
        if (this.formData.intId && this.formData.strSessaoSocket) {
          //this.atualizarSessao();
        }
      },
      deep: true,
    },
    "bind.players": {
      handler: function (newVal) {},
      deep: true,
    },
  },
};
</script>