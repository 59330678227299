<template>
  <div class="row">
    <v-navigation-drawer
      v-model="bind.boolExibirChat"
      class="text-center"
      right
      fixed
      app
      temporary
      floating
      width="400"
      style="
        z-index: 99;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        overflow: hidden !important;
      "
    >
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Mensagens na Chamada
              <v-divider></v-divider>
            </v-list-item-title>
            
          </v-list-item-content>
        </v-list-item>
      </template>


      <v-list-item>
        <v-list-item-content>
          <div
            class="row"
            id="chat"
            style="height: 100%; overflow-y: auto; font-size: 11pt"
          >
            <div class="col-12 text-left">
              <template v-for="(objMensagem, index) in arrayMensagem">
                <template v-if="objMensagem.sessao">
                  <span
                    :key="'user-' + index"
                    style="width: 100%; display: block"
                  >
                    <b>{{ objMensagem.sessao.strNome }}</b>
                    <label
                      style="margin-left: 5px; color: #666666; font-size: 10pt"
                    >
                      {{
                        $utilidade.toDate(
                          objMensagem.strDataCadastro,
                          false,
                          "DD MMM HH:mm"
                        )
                      }}</label
                    >
                  </span>

                  <span
                    class="pb-4"
                    :key="'message-' + index"
                    style="width: 100%; display: block"
                  >
                    {{ objMensagem.strMensagem }}
                  </span>
                </template>
              </template>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append>
        <div class="row pa-0 ma-0">
          <div class="col-12 pa-0 mt-2">
            <ed-input-text
              placeholder="Enviar mensagem para todos"
              v-model="formDataMensagem.strMensagem"
              :iconRight="$utilidade.getIcone('enviar')"
              @enter="enviarMensagem"
            />
          </div>
          <!-- <div class="col-12 pa-0 ma-0 mb-2">
            <ed-button
              :iconLeft="$utilidade.getIcone('enviar')"
              :disabled="formDataMensagem.strMensagem ? false : true"
              label="Enviar"
              @click="enviarMensagem"
            />
          </div> -->
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  EdButton,
  EdAlert,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
} from "@/components/common/form";

export default {
  name: "EdTable",
  components: {
    EdButton,
    EdAlert,
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
  },
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  mixins: [],
  inject: ["consultaVideoChamada", "bind", "playSound"],
  beforeDestroy() {},
  data() {
    return {
      drawer: false,
      arrayMensagem: [],
      formDataMensagem: {
        intConsultaVideoId: this.consultaVideoChamada.intId,
        intConsultaVideoSessaoId: this.formData.intId,
        strMensagem: null,
      },
    };
  },
  created() {},
  mounted() {
    let self = this;

    this.getMensagem();
    this.$root.$socket.subscribe(
      this.consultaVideoChamada.strIdentificador,
      "videoChamadaChat",
      function (objRetorno) {
        self.getMensagem();

        if (objRetorno.intConsultaVideoSessaoId != self.formData.intId) {
          self.$root.$message.success(
            "<b>" +
              objRetorno.sessao.strNome +
              "</b>" +
              "<br>" +
              objRetorno.strMensagem
          );
          self.playSound("chat");
        }
      }
    );
  },
  computed: {},
  methods: {
    getMensagem() {
      this.$root.$request
        .get("publico/video-chamada-mensagem")
        .then((objResponse) => {
          if (objResponse.status == 200) {
            console.log("objRetorno mensagem", objResponse);
            this.arrayMensagem = Object.assign(objResponse.result.data, {});
            this.$emit("mensagem", this.arrayMensagem.length);
            this.scroolBottom();
          }
        });
    },

    scroolBottom() {
      var mydiv = document.getElementById("chat");
      mydiv.scrollTop = mydiv.scrollHeight;
    },

    enviarMensagem() {
      if(!this.formDataMensagem.strMensagem)return;


      let formDataMensagem = _.cloneDeep(this.formDataMensagem);

      this.arrayMensagem.push({
        strMensagem: this.formDataMensagem.strMensagem,
        sessao: this.formData,
        strDataCadastro: this.$utilidade.toDate(
          null,
          false,
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
      this.formDataMensagem.strMensagem = null;

      this.$root.$request
        .post("publico/video-chamada-mensagem", formDataMensagem)
        .then((objResponse) => {});
    },
  },
  watch: {
    arrayMensagem: {
      handler: function (newVal, oldVal) {
        this.scroolBottom();
      },
      deep: true,
    },
    formData: {
      handler: function (newVal, oldVal) {},
      deep: true,
    },
  },
};
</script>
