<template>
  <div class="row ma-0 pa-3">
    <ed-video v-if="formData" :consultaVideoChamada="formData" ></ed-video>

    <ed-alert class="col-12 text-left " type="error" v-if="bind.objError">
      {{bind.objError}}
    </ed-alert>
  </div>
</template>

<script>
import { EdAlert} from "@/components/common/form";
import EdVideo from "@/components/consulta/video/index";
export default {
  name: "pageIndex",
  props: {},
  components: {
    EdVideo,
    EdAlert
  },
  mounted() {
    this.getConsultaVideoChamada();
  },
  data() {
    return {
      formData: null,
      bind:{
        objError:null,
      }
    };
  },
  methods: {
    getConsultaVideoChamada() {

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("publico/video-chamada", {
          strIdentificador: this.$route.params.strIdentificador,
        })
        .then((objResponse) => {
          if(objResponse.status == 200){
            if(objResponse.result){
              this.formData = Object.assign(objResponse.result.objConsultaVideo,{strSessao:objResponse.result.strSessao})
            }
            else{
              this.bind.objError = 'Não foi possível carregar a video chamada'
            }
          }
          else{
            this.bind.objError = objResponse.msg
          }
          this.$root.$dialog.loading(false);
        });
    },
  },
};
</script>

